<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gateway.vue
Description:This file is combination of deviceInfoTab,gatewaysInfoTab files
-->
<template>
  <layout-pondlogs headerType="custom" class="resources-view">
    <template slot="layout-pondlogs-scrollable-main">
      <er-card>
        <er-top-route-nav
          :items="filterTabComponents"
          type="tabs"
        ></er-top-route-nav>
        <el-row>
          <component
            :loading="loading"
            :is="computedComponentName"
            @change-tab="handleChangeTabTo"
            @refresh-click="handleRefresh"
          />
        </el-row>
      </er-card>
    </template>
  </layout-pondlogs>
</template>

<script>
import deviceInfoTab from "@/components/manage/deviceInfoTab";
import gatewaysInfoTab from "@/components/manage/gatewaysInfoTab";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/base/Loader";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
const tabToPermission = {
  deviceInfoTab: {
    name: "deviceInfoTab",
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_SCHEDULES,
        permissions.MANAGE_DEVICES
      ]
    }
  },
  gatewaysInfoTab: {
    name: "gatewaysInfoTab",
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_SCHEDULES,
        permissions.MANAGE_DEVICES
      ]
    }
  }
};
export default {
  mixins: [errorHandlerMixin, redirectsMixin],
  components: {
    deviceInfoTab,
    gatewaysInfoTab,
    Loader
  },
  data: function () {
    return {
      currentComponent: "Loader",
      componentName: "deviceInfoTab",
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    getCurrUserLocationId: function () {
      return this.getCurrUserLocation._id;
    },
    components() {
      return {
        deviceInfoTab: {
          label: this.$t("Comn_device_info"),
          ...tabToPermission.deviceInfoTab
        },
        gatewaysInfoTab: {
          label: this.$t("Gateway_gateway_info"),
          ...tabToPermission.gatewaysInfoTab
        }
      };
    },
    filterTabComponents: function () {
      return this.$gblUAMFilterItemsUserCanView(this.components);
    },
    computedComponentName: function () {
      return this.$route.query.tab;
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((component) => {
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/gateway",
          query: {
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("gateway", {
      fetchGatewayDeviceInfoData: "fetchGatewayDeviceInfoData",
      fetchGatewayInfoData: "fetchGatewayInfoData"
    }),
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchGatewayInfoData({
          location_id: this.getCurrUserLocation._id
        });
        await this.fetchGatewayDeviceInfoData({
          location_id: this.getCurrUserLocation._id
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleChangeTabTo: function (tabComponentName) {
      this.componentName = tabComponentName;
    },
    async handleRefresh() {
      await this.initComponent();
    }
  }
};
</script>

<style lang="scss">
.resources-view {
  $default-font-size: 13px;
  $font-color: #404243;
  // .el-tabs__header {
  //   margin: 0 0 0 !important;
  // }
  // .action-toolbar {
  //   margin-bottom: 10px;
  // }
  .el-menu--horizontal.type-tab {
    width: 100%;
    margin-bottom: 5px;
    > .el-menu-item {
      line-height: 35px;
      height: 35px;
    }
  }
  .el-card__body {
    padding: 0px;
  }
}
</style>
